.hero {
  text-align: center;
  font-size: 3.6rem;
  color: $hero-text;
  margin-top: 30px;
}

.hero-image {
  margin: 50px auto 0;
  display: block;
  @include bp(baby-bear) {
    margin-top: 70px;
  }
}




.information {
	@extend .wrap;
  padding: 2%;
	font-weight: $body-weight;
}

article {
  font-size: 3rem;
  border-top: 1px solid $bar;
  margin: 40px auto;
  padding-top: 40px;
  h2 {
    text-transform: uppercase;
    font-size: 67px;
    font-size: 38.4px;
    font-size: 38.4px;
    font-size: 6rem;
    margin: 0 0 40px;
    padding: 0 20px;
    line-height: 100%;
    text-align: center;
  }
  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .desc {
    padding: 0 20px;
  }
  @include bp(mama-bear) {
    article {
      width: 96%;
      margin: 0 2%;
      overflow: hidden;
      *zoom: 1;
      margin: 40px auto;
      font-size: 2.6rem;
    }
  }
}

.about {
  padding-top: 2px;
  h2 {
    text-align: center;
    margin: 40px 0;
  }
}

.desc {
  margin-top: 20px;
}

@include bp(mama-bear) {
  .about {
    .desc, img {
      float: left;
      margin-right: -100%;
      width: 49.5%;
    }
  }
  .about .desc {
    margin-left: 50.5%;
    margin-top: 0;
  }
}

.accomodation h2 {
  padding: 0;
  position: relative;
  @include bp(mama-bear) {
      right: 10px;
  }
}


@include bp(mama-bear) {
  .accomodation {
    .desc, h2 {
      float: left;
      margin-right: -100%;
      width: 49.5%;
    }
  }
  .bistro {
    .desc, h2 {
      float: left;
      margin-right: -100%;
      width: 49.5%;
    }
  }
  .accomodation .desc, .bistro .desc {
    margin-top: 80px;
  }
  .accomodation img, .bistro img {
    float: left;
    margin-right: -100%;
    width: 49.5%;
    margin-left: 50.5%;
  }
  .accomodation h2, .bistro h2 {
    text-align: right;
  }
  .functions {
    h2 {
      text-align: left;
    }
    .desc, h2 {
      float: left;
      margin-right: -100%;
      width: 49.5%;
      margin-left: 50.5%;
    }
    .desc {
      margin-top: 80px;
    }
    img {
      float: left;
      margin-right: -100%;
      width: 49.5%;
    }
  }
}

.entertainment {
  max-width: 100%;
  border-top: 1px solid $bar;
  padding-top: 40px;
}

@include bp(mama-bear) {
  .entertainment {
    width: 96%;
    margin: 0 2%;
  }
}

.catering-packages {
  padding-top: 0;
  h2 {
    text-align: center;
  }
  .catering, .packages {
    padding-top: 40px;
  }
}

@include bp(mama-bear) {
  .catering-packages {
    .catering {
      float: left;
      margin-right: -100%;
      width: 49.5%;
    }
    .packages {
      float: left;
      margin-right: -100%;
      width: 49.5%;
      margin-left: 50.5%;
    }
  }
}

.contact {
  width: 96%;
  margin: 0 2%;
  overflow: hidden;
  *zoom: 1;
  margin: 0 auto;
  background-position: 50%;
  background-size: cover;
  padding-bottom: 10px;
  h2 {
    text-align: center;
    font-size: 67px;
    font-size: 42.88px;
    font-size: 42.88px;
    font-size: 6.7rem;
    white-space: nowrap;
  }
  p {
    text-transform: uppercase;
    margin-left: 10px;
    margin-left: 6.4px;
    margin-left: 6.4px;
    margin-left: 1rem;
    font-size: 35px;
    font-size: 22.4px;
    font-size: 22.4px;
    font-size: 3.5rem;
    margin-bottom: 15px;
    margin-bottom: 9.6px;
    margin-bottom: 9.6px;
    margin-bottom: 1.5rem;
  }
  img {
    float: right;
  }
  .error {
    font-size: 20px;
    font-size: 12.8px;
    font-size: 12.8px;
    font-size: 2rem;
    &.message {
      float: left;
    }
  }
  .success {
    background: $light;
    padding: 15px;
    border: 2px solid #3c763d;
  }
  .has-error, .success {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .has-error {
    border: 1px solid $primary;
  }
  form {
    input, textarea {
      display: block;
      width: 100%;
      margin: 20px 0;
      padding: 10px 10px 30px;
      font-size: 24px;
      font-size: 15.36px;
      font-size: 15.36px;
      font-size: 2.4rem;
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
    }
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
      color: $black;
      text-transform: uppercase;
    }
    input {
      &#email {
        padding: 10px 10px 30px;
        margin-top: 10px;
      }
    }
  }
  .small-logo {
    margin-top: -10px;
    margin-bottom: 5px;
  }
}

.service-list {
  padding-left: 4rem;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 4rem;
  li {
    margin-top: 1rem;
    font-size: 2.5rem;
  }
}

.footnote {
  font-size: 2rem;
}

.form-group {
  width: 100%;
  @include bp(baby-bear) {
    width: 50%;    
  }
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;

  textarea {
    border-color: #eee;
  }
  .form-choice {
    font-weight: 300;
  }
  &-checkbox {
    input {
      float: left;
      width: auto;
      margin-right: 3rem;
      margin-top: 0.5rem;
    }
  }
}
label {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
label, input, select, textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
.form-message > p {
  margin: 2rem;
  padding: 2rem;
  border-left-color: $red;  
  border-bottom-color: $red;    
  border-bottom: 1px solid;
  border-left: 1px solid;
}
.form-message .form-success {
  border: solid 1px green;
}

.btn-full {
  width: 100%;
}

@include bp(mama-bear) {
  .contact form {
    float: left;
    margin-right: -100%;
    width: 41.08%;
    margin-left: 8.42%;
  }
}

@include bp(mama-bear) {
  .contact .small-logo {
    margin-top: 10px;
  }
}

.group:before,
.group:after {
  content: "";
  display: table;
} 
.group:after {
  clear: both;
}
.group {
  zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}
.group-flex{
  display: flex;
  & > div {
    margin: 0;
  }
}

.vertical-align {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

@include bp(mini-bear) {
  margin-top: 70px;
  @include generate_helper_classes();
}