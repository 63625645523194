//Colours

$red: #ED1C24;

$primary: $red;
$grey: #999999;
$black: #000000;

$bar: #cccccc;
$light: #ffffff;
$hero-text: #999;

// Fonts

$header-text:$black;
$header-weight:600;
$header-hover: lighten($header-text, 50%);

$body-text:$black;
$body-weight:300;

//Breakpoints
@mixin bp($point) {
  @if $point == papa-bear {
    @media (min-width: 1270px) { @content; }
  }
  @else if $point == mama-bear {
    @media (min-width: 970px) { @content; }
  }
  @else if $point == baby-bear {
    @media (min-width: 750px)  { @content; }
  }
  @else if $point == mini-bear {
    @media (min-width: 380px)  { @content; }
  }
}