footer {
  background: $grey;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-weight: 400;
  color: $light;
  a, h4 {
    color: $light;
  }
  a {
    text-decoration: none;
    transition: color 0.5s;
    &:hover {
      color: $primary;
      transition: color 0.5s;
    }
  }
}

@include bp(mama-bear) {
  footer {
    height: 150px;
  }
}

.footer-wrap {
  padding: 40px 0;
}

.main-footer {
  padding: 15px 0;
  font-size: 22px;
  font-size: 14.08px;
  font-size: 14.08px;
  font-size: 2.2rem;
  text-align: center;
  a {
    color: $light;
  }
  p {
    margin-bottom: 10px;
  }
  > div {
    padding-top: 10px;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      border-right: none;
    }
  }
}

@include bp(mama-bear) {
  .main-footer {
    width: 96%;
    margin: 0 2%;
    overflow: hidden;
    *zoom: 1;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: left;
    overflow: visible;
    padding: 0;
  }
}

@include bp(mama-bear) {
  .main-footer > div {
    height: 69px;
    border-right: 2px solid $light;
    padding-left: 30px;
    padding-right: 10px;
  }
}

.footer-logo {
  padding-bottom: 10px;
  img {
    margin: -10px 0 0;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    left: -20px;
    width: 55px;
    height: 50px;
  }
  h4 {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    font-weight: 400;
    line-height: 170%;
  }
}

@include bp(papa-bear) {
  .footer-logo img {
    width: 77px;
    height: 70px;
  }
}

@include bp(mama-bear) {
  .footer-logo {
    padding-bottom: 0;
  }
}

@include bp(mama-bear) {
  .address, .footer-logo {
    float: left;
    margin-right: -100%;
    width: 24.25%;
  }
  .address {
    margin-left: 25.25%;
  }
  .phone {
    float: left;
    margin-right: -100%;
    width: 24.25%;
    margin-left: 50.5%;
  }
}

.email {
  text-transform: none;
}

@include bp(mama-bear) {
  .email {
    float: left;
    margin-right: -100%;
    width: 24.25%;
    margin-left: 75.75%;
  }
}

.facebook {
  margin-top: 15px;
  display: block;
  img {
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -5px;
  }
}

@include bp(mama-bear) {
  .facebook {
    margin-top: 10px;
  }
}

.call-us {
  margin: 5px 0;
}

@include bp(mama-bear) {
  .call-us {
    display: none;
  }
}