header {
  @include container();
  @include clearfix();
  @extend .wide-wrap;
  position: fixed;
  top: 0;
  text-transform: uppercase;
  font-size: 1.7rem;
  background: $light;
  margin-top: 15px;
  z-index: 1;
  .header-logo {
    background: $light;
    min-height: 50px;
    margin-top: 0;
    @include bp(mama-bear) {
      float: left;
      margin-right: -100%;
      width: 49.5%;
    }
    @include bp(papa-bear) {
      float: left;
      margin-right: -100%;
      width: 32.67%;
    }
  }
  img {
    width: 77px;
    height: 69px;
    vertical-align: middle;
    display: block;
    display: inline-block;
    margin: 0 15px;
    @include bp(mini-bear) {
      margin: 0 20px 0 0;
    }
    @include bp(mama-bear) {
      display: inline-block;
    }
  }
  h1 {
    display: block;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    height: 100%;
    padding-bottom: 20px;
    font-weight: $header-weight;
    font-size: 10.88px;
    font-size: 1.7rem;
    display: inline-block;
    padding: 0;
    @include bp(mama-bear) {
      display: inline-block;
    }
  }
  @include bp(mini-bear) {
    padding: 0 30px;
	}
	@include bp(mama-bear) {
    background: $light;
    font-size: 1.5rem;
	}
}

.main-menu {
  display: none;
  vertical-align: middle;
  border-top: 1px solid $bar;
  border-bottom: 1px solid $bar;
  font-size: 2.2rem;
  ul {
    margin: 10px 0;
    @include bp(mama-bear) {
      display: inline-block;
      margin: 0;
      margin: 10px 0 0;
      float: right;
      font-size: 1.4rem;
    }
    @include bp(papa-bear) {
       margin: 15px 0 0;
    }
  }
  li {
    list-style: none;
    text-align: center;
    margin: 5px 0;
    a {
      text-decoration: none;
      font-weight: 600;
      transition: color .5s;
      color: $black;
      &:visited {
        color: $black;
      }
      &:hover {
        color: $primary;
        transition: color 0.5s;
      }
    }
    &:last-of-type {
      border-right: none;
    }
    @include bp(mama-bear) {
      float: left;
      padding: 0 10px;
      border-right: 2px solid $black;
    }
  }
  @include bp(mama-bear) {
      display: block;
      border: none;
      margin: 0;
      font-size: 1.7rem;
      float: left;
      margin-right: -100%;
      width: 49.5%;
      margin-left: 50.5%;
  }
  @include bp(papa-bear) {
    float: left;
    margin-right: -100%;
    width: 66.33%;
    margin-left: 33.67%;
  }
}


.menu-icon {
  text-align: center;
  margin: 15px -5px 0 0;
  position: absolute;
  right: 10px;
  span {
    display: none;
  }
  &:before {
    background: url(../images/icon-png2x.png) 0 no-repeat;
    background-size: cover;
    padding-left: 1.6em;
    content: '';
    margin-right: 10px;
  }
}

@include bp(baby-bear) {
  .menu-icon span {
    display: inline;
  }
}

@include bp(baby-bear) {
  .menu-icon {
    margin: 20px;
  }
}

@include bp(mama-bear) {
  .menu-icon {
    display: none;
  }
}

.head-logo {
  display: inline-block;
}

@include bp(mama-bear) {
  .head-logo {
    display: inline-block;
  }
}