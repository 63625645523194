* {
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  margin: 0;
  padding: 0;
}


html {
  height: 100%;
  font-size: 40%;
  @include bp(baby-bear) {
     font-size: 53.3%;
  }
  @include bp(mama-bear) {
     font-size: 62.5%;
  }
}


body {
	height:100%;
	font-size: 1.6em;
}

h1,h2, h3,h4,h5,h6{
	font-weight:$body-weight;
	color:$header-text;
	text-transform:uppercase;
}

img {
	max-width:100%;
}

.wide-wrap {
	margin: 0 auto;
	width:1600px;
	max-width:100%;
}
.wrap {
	width:1150px;
	max-width:100%;
	margin: 0 auto;
	padding: 2%;
}

.small-logo {
  width: 55px;
  height: 50px;
  @include bp(baby-bear) {
      width: 77px;
      height: 70px;
  }
}

.http-error {
  padding: 10%;
  overflow: hidden;
  *zoom: 1;
  h1 {
    text-align: center;
    font-size: 44.8px;
    font-size: 7rem;
    margin-top: 30px;
  }
}

header {
  margin: 0 auto;
  width: 1600px;
  max-width: 100%;
}

.wrap {
  width: 1150px;
  max-width: 100%;
  margin: 0 auto;
  padding: 2%;
}

.margin-top-0 {
  margin-top: 0;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.max-width-100 {
  max-width: 100%;
}
.center-block {
  display: block;
  margin: 0 auto;
}



button {
  border: none;
  background: $primary;
  font-size: 16px;
  font-size: 10.24px;
  font-size: 10.24px;
  font-size: 10.24px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  height: 33px;
  width: 80px;
  padding: 0;
  margin: 0;
  float: right;
}
button[disabled] {
  cursor: not-allowed;
  background: rgba($primary, 0.5);
}
input, select, textarea {
  border-top:none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid green;
}
input:invalid, select:invalid, textarea:invalid{ 
  border-bottom: 1px solid red;
}

.g-recaptcha {
  padding: 3rem 0;
}
.g-recaptcha > div {
  margin: 0 auto;
}

.call-btn {
  text-align: center;
  display: block;
  margin: 0 auto 3rem auto;
  border: none;
  background: $primary;
  font-size: 2rem;
  font-weight: 600;
  width: 50%;
  color: #ffffff;
  text-transform: uppercase;
  padding: 2rem;
  border-radius: 4rem;
  text-decoration: none;
}

.hero-image {
  .hero-image-small {
    display:block;
    margin: 0 auto;
    @include bp(baby-bear){
      display:none
      }
  }
  .hero-image-large {
    display: none;    
    @include bp(baby-bear){
      display:block
      }
  }
}

