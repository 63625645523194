.lannteli {
	width:300px;
	max-width: 100%;
	margin: 0 auto;
	padding-bottom:50px;
	img {
		width: 120px;
		height:auto;
		margin: 0 auto;
		display: block;
	}
	p {
		text-align: center;
		a {
			color:black;
			text-decoration: none;
			transition: color 1s;
			&:hover {
				color: #0066a4;
			}
			&:after {
				clear:both;
				display:block;
				content:"";
				position: relative;
				width:0;
				height: 1px;
				background: #0066a4;
				transition: width 1.5s;
			}
			&:hover:after {
				width:100%;
				transition: width 1.5s;
			}
		}
	}
}