.testimonial {
  width: 50%;
  padding: 4rem;
  font-size: 2rem;
  &-desc {
  line-height: 1.8;  
  }
  &-author {
    margin-top: 1rem;
    font-weight: 400;
  }
  &-type {
    font-style: italic;
    margin-top: 1rem;
  }
  &-type:before {
    content: "~";
    padding-right: 1rem;
  }
}

blockquote {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
    content: open-quote;
    padding-right: 0.5rem;
}
blockquote:after {
    content: close-quote;
    padding-left: 0.5rem;
}